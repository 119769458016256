import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Button, CircularProgress, Typography } from '@mui/material';
import SalesPageGraphs from './SalesPageGraphs';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function TodaysGraphsPage() {
  const [todaysSales, setTodaysSales] = useState([]);
  const [ordersToday, setOrdersToday] = useState([]); // State for orders today
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch today's sales and orders data when the component mounts
  useEffect(() => {
    axios
      .get('https://shopifybackend.mustafaemresahin.com/api/todays-sales')
      .then((response) => {
        setTodaysSales(response.data.salesData || []); // Default to empty array if no data
        setOrdersToday(response.data.ordersToday || []); // Default to empty array if no data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching today's sales:", error);
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
      });
  }, []);

  const handleClick = () => {
    navigate(`/`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f9f9f9',
        }}
      >
        <CircularProgress size={60} thickness={4.5} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: '20px', padding: '20px' }}>
        <Typography color="error" sx={{ fontSize: '1.25rem', fontWeight: '500' }}>
          {error}
        </Typography>
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            mt: 3,
            backgroundColor: '#1976d2',
            '&:hover': { backgroundColor: '#125ea3' },
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  if (todaysSales.length === 0 && ordersToday.length === 0) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
        }}
      >
        <Typography sx={{ fontSize: '1.25rem', fontWeight: '500', color: '#555' }}>
          No sales or orders available for today.
        </Typography>
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            mt: 3,
            backgroundColor: '#1976d2',
            '&:hover': { backgroundColor: '#125ea3' },
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        padding: '30px',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          mb: 3,
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
          '&:hover': { backgroundColor: '#125ea3' },
        }}
      >
        Back to Dashboard
      </Button>

      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 3,
          color: '#333',
        }}
      >
        Sales Graphs
      </Typography>

      <Box>
        <Grid container spacing={3}>
          {todaysSales.map((product) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={product.shopifyId}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: '20px',
                  borderRadius: '8px',
                  width: '100%',
                  textAlign: 'center',
                  backgroundColor: '#ffffff',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <SalesPageGraphs
                  productId={product.shopifyId}
                  quantitySoldToday={product.quantity} // Pass the quantity sold today
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    fontWeight: '500',
                    color: '#555',
                  }}
                >
                  {product.title}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default TodaysGraphsPage;
