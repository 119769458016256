import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  CircularProgress,
  Snackbar,
  IconButton,
  Modal,
  Paper,
  Container,
  Button,
  Switch,
  FormControlLabel, Checkbox, Tabs, Tab, Grid
} from '@mui/material';
import Popper from '@mui/material/Popper';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import Autocomplete from '@mui/material/Autocomplete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

function InventoryCount() {
  const { sessionId } = useParams(); // Get sessionId from URL params
  const [session, setSession] = useState(null);
  const [products, setProducts] = useState([]);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [loading, setLoading] = useState(true); // Initially set loading to true
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false); // State for settings modal
  const [newSessionName, setNewSessionName] = useState('');
  const [audioEnabled, setAudioEnabled] = useState(true); // State to manage audio
  const [audioAlertVisible, setAudioAlertVisible] = useState(true); // State for audio alert visibility
  const [audioDisabledAlertVisible, setAudioDisabledAlertVisible] = useState(false); // State for audio disabled alert
  const navigate = useNavigate();
  const [incrementValue, setIncrementValue] = useState(1); // Increment/Decrement value
  const [scanMode, setScanMode] = useState('increment'); // Mode for increment/decrement
  const [checkedProducts, setCheckedProducts] = useState([]); // List of selected products
  const [finishStatus, setFinishStatus] = useState(false); // Track if the session is finished
  const [openAddBarcodeModal, setOpenAddBarcodeModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allProducts, setAllProducts] = useState([]); // All products, for case barcode option
  const [allProducts1, setAllProducts1] = useState([]); // All products, for case barcode option
  const [quantity, setQuantity] = useState(1); // Quantity for case
  const [tabValue, setTabValue] = useState(0); // 0 for individual, 1 for case
  const [openValidationModal, setOpenValidationModal] = useState(false); // Modal visibility state
  const [isLoading, setIsLoading] = useState(false); // Loading state for animation
  const [submittedProducts, setSubmittedProducts] = useState([]); // Data for submitted products
  const [isContinueEnabled, setIsContinueEnabled] = useState(false); // Controls if "Continue" button is enabled

  const handleOpenAddBarcodeModal = () => {
      setOpenAddBarcodeModal(true);
  };

  useEffect(() => {
    // Load audio settings from local storage on component mount
    const storedAudioSetting = localStorage.getItem('audioEnabled');
    if (storedAudioSetting !== null) {
      setAudioEnabled(JSON.parse(storedAudioSetting));
    }

    if (!sessionId) return; // Exit if sessionId is not available

    const fetchSessionData = async () => {
      try {
        console.log('Fetching session data...');
        const sessionResponse = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${sessionId}`);
        
        console.log('Session data fetched:', sessionResponse.data); // Log sessionResponse.data directly
        setSession(sessionResponse.data); // Set session state
        setFinishStatus(sessionResponse.data.status === 'finished'); // Set session status to finished if applicable
        setNewSessionName(sessionResponse.data.sessionName);
      } catch (error) {
        console.error('Error fetching session data:', error);
        setError('Failed to load session data.');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };    

    const fetchBarcodes = async () => {
      try {
          const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/products/barcodes');
          setProducts(response.data);
      } catch (error) {
          console.error('Error fetching products:', error);
      }
    };

    const fetchAllProducts = async () => {
      try {
        // Fetch products with inventory for inventory count
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/products-for-inventorycount');
        setAllProducts(response.data);
        console.log('Products for inventory count:', response.data); // Log the output of products for inventory count
    
        // Fetch all products
        const response1 = await axios.get('https://shopifybackend.mustafaemresahin.com/api/products');
        setAllProducts1(response1.data);
        console.log('All products:', response1.data); // Log the output of all products
    
      } catch (error) {
        console.error('Error fetching all products:', error);
      }
    };    
  
    fetchAllProducts();

    fetchBarcodes();

    fetchSessionData();

  }, [sessionId]);

  // Function to play a beep sound with a specified frequency
  const playSound = (frequency) => {
    if (!audioEnabled) {
      setAudioDisabledAlertVisible(true); // Show alert if audio is disabled
      return;
    }
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)(); // Create audio context
    const oscillator = audioCtx.createOscillator(); // Create an oscillator node
    const gainNode = audioCtx.createGain(); // Create a gain node to control volume

    oscillator.type = 'square'; // Set waveform type
    oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime); // Set frequency (Hz)
    gainNode.gain.setValueAtTime(0.1, audioCtx.currentTime); // Set volume

    oscillator.connect(gainNode); // Connect oscillator to gain node
    gainNode.connect(audioCtx.destination); // Connect gain node to audio context destination

    oscillator.start(); // Start the oscillator
    oscillator.stop(audioCtx.currentTime + 0.5); // Stop after 0.5 seconds
  };

  const playSuccessSound = () => playSound(660); // High tone for success
  const playErrorSound = () => playSound(220); // Low tone for error

  const handleScan = async () => {
    setError('');
    setSuccessMessage('');
  
    try {
      // First, attempt to check if the scanned barcode is for a case
      console.log(`Checking case for scanned barcode: ${scannedBarcode}`);
      const caseResponse = await axios.get(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/case/${scannedBarcode}`);
      const caseItem = caseResponse.data;
  
      if (caseItem) {
        // If the scanned barcode is a case, process it as a case and use the product title
        console.log('Received case item from backend:', JSON.stringify(caseItem, null, 2));
  
        const productTitle = caseItem.productId.title; // Using the product title for identification
        const productBarcode = caseItem.caseBarcode; // Case barcode for uniqueness in the backend
  
        let adjustmentMultiplier = incrementValue;
        if (scanMode === 'decrement') {
          adjustmentMultiplier = -adjustmentMultiplier;
        }
  
        const adjustment = Number(caseItem.quantity) * adjustmentMultiplier;
  
        // Check for the existing item in session by the title of the product
        const existingItemIndex = session?.items.findIndex(item => item.title === productTitle);
  
        if (existingItemIndex !== -1) {
          // If the case's product already exists in the session, update the counted inventory
          const updatedItems = [...session.items];
          const currentCount = Number(updatedItems[existingItemIndex].countedInventory) || 0;
          updatedItems[existingItemIndex].countedInventory = currentCount + adjustment;
  
          setSession(prev => ({ ...prev, items: updatedItems }));
        } else {
          // Add the case's product as a new item if it doesn't exist in the session
          const currentInventory = Number(caseItem.productId.inventoryLevels?.[0]?.quantity) || 0;
  
          const newItem = {
            barcode: productBarcode, // Still track the barcode for backend reference
            title: productTitle, // Use title as the unique identifier for session
            currentInventory,
            countedInventory: adjustment,
          };
  
          setSession(prev => ({ ...prev, items: [...prev.items, newItem] }));
        }
  
        // Send updated session information to the backend for the case product
        await axios.put(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/add-item`, {
          item: {
            barcode: productBarcode, // Send the case barcode for reference in the backend
            title: productTitle, // Use the title to refer to the specific product in the case
            currentInventory: Number(caseItem.productId.inventoryLevels?.[0]?.quantity) || 0,
          },
          adjustment, // Send the total adjustment value for the case
        });
  
        setSuccessMessage(`Scanned case: ${caseItem.caseBarcode}, product: ${productTitle} (x${adjustment})`);
        setOpenSnackbar(true);
        playSuccessSound();
  
        setScannedBarcode(''); // Clear after processing
        return; // Exit early if it was a case barcode
      }
    } catch (error) {
      console.error('Error checking case barcode:', error.message);
    }
  
    // Handle individual product barcode logic (if the barcode isn't for a case)
    const matchedProduct = products.find(p => p.barcode === scannedBarcode);
    if (!matchedProduct) {
      setError(
        <>
          Product not found for the scanned barcode.{' '}
          <Button variant="text" color="primary" onClick={handleOpenAddBarcodeModal}>
            Add New Barcode
          </Button>
        </>
      );
      setOpenSnackbar(true);
      playErrorSound();
      return;
    }
  
    // Use title to find the item in session for individual products
    const existingItemIndex = session?.items.findIndex(item => item.title === matchedProduct.title);
  
    let adjustmentMultiplier = incrementValue;
    if (scanMode === 'decrement') {
      adjustmentMultiplier = -adjustmentMultiplier;
    }
  
    if (existingItemIndex !== -1) {
      // Update the countedInventory if the individual product already exists by title
      const updatedItems = [...session.items];
      updatedItems[existingItemIndex].countedInventory += adjustmentMultiplier;
  
      setSession(prev => ({ ...prev, items: updatedItems }));
    } else {
      // Add a new individual product if it doesn't exist in the session
      const newItem = {
        barcode: scannedBarcode, // Keep barcode for backend but use title for uniqueness
        title: matchedProduct.title, // Use title for uniqueness in session
        currentInventory: matchedProduct.currentInventory,
        countedInventory: adjustmentMultiplier,
      };
  
      setSession(prev => ({ ...prev, items: [...prev.items, newItem] }));
    }
  
    setSuccessMessage(`Scanned: ${matchedProduct.title}`);
    setOpenSnackbar(true);
    playSuccessSound();
  
    // Save the individual product to backend
    try {
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/add-item`, {
        item: {
          barcode: scannedBarcode, // Send the individual product barcode
          title: matchedProduct.title, // Use the title to refer to the product
          currentInventory: matchedProduct.currentInventory,
        },
        adjustment: adjustmentMultiplier, // Send the adjustment value
      });
    } catch (error) {
      console.error('Error adding item to session:', error);
      setError('Failed to add scanned item.');
      setOpenSnackbar(true);
      playErrorSound();
    }
  
    setScannedBarcode(''); // Clear after the API call
  };  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenSettingsModal = () => {
    setOpenSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
    setAudioDisabledAlertVisible(false); // Reset the alert visibility when closing the settings modal
  };

  const toggleAudioEnabled = () => {
    setAudioEnabled(prev => {
      const newAudioEnabled = !prev;
      localStorage.setItem('audioEnabled', JSON.stringify(newAudioEnabled)); // Save the new setting to local storage
      return newAudioEnabled;
    });
  };

  const saveSessionName = async () => {
    try {
      setLoading(true);
      const updatedSession = { sessionName: newSessionName };  // Prepare the updated session data
  
      // Correct the endpoint URL and method to match the updated backend route
      const response = await axios.put(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/name`, updatedSession);
  
      setSession(response.data);  // Update the session state with the server response
      setOpenModal(false);  // Close the modal after a successful update
    } catch (error) {
      console.error('Error updating session name:', error.response ? error.response.data : error.message);
      setError('Failed to update session name.');
      setOpenSnackbar(true);  // Show an error message to the user
    } finally {
      setLoading(false);  // Ensure loading state is reset
    }
  };  

  const handleFinishSession = async () => {
    try {
      setLoading(true);
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/finish`);
      setFinishStatus(true);
      setSuccessMessage('Session finished.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error finishing session:', error);
      setError('Failed to finish session.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleResumeSession = async () => {
    try {
      setLoading(true);
      await axios.put(`https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/resume`);
      setFinishStatus(false);
      setSuccessMessage('Session resumed.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error resuming session:', error);
      setError('Failed to resume session.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSelectProduct = (barcode) => {
    setCheckedProducts((prev) => {
      if (prev.includes(barcode)) {
        return prev.filter(id => id !== barcode);
      } else {
        return [...prev, barcode];
      }
    });
  };
  
  const handleSubmitSelectedProducts = async () => {
    try {
      console.log("Starting submission of selected products...");

      // Prepare payload based on selected products
      const selectedProductsPayload = session.items
        .filter(item => checkedProducts.includes(item.barcode))
        .map(product => ({
          title: product.title,
          previousInventory: product.currentInventory,
          newInventory: product.countedInventory,
        }));
      
      console.log("Selected Products Payload:", selectedProductsPayload);

      if (selectedProductsPayload.length === 0) {
        console.error('No products selected for submission.');
        return;
      }

      // Set submitted products for validation modal
      setSubmittedProducts(selectedProductsPayload);
      console.log("Opening validation modal...");
      setOpenValidationModal(true); // Open modal to show loading animation
      setIsLoading(true); // Display loading spinner
      setIsContinueEnabled(false); // Disable continue button until processing is complete

      // Delay to simulate loading time (e.g., animation delay)
      setTimeout(() => {
        console.log("Ending loading animation, enabling continue button...");
        setIsLoading(false);
        setIsContinueEnabled(true); // Enable continue button after delay
      }, 5000);

      // Submit selected products to backend
      console.log("Sending request to backend to update selected products...");
      const response = await axios.put(
        `https://shopifybackend.mustafaemresahin.com/api/inventory-counts/${session._id}/submit-selected`,
        { selectedProducts: selectedProductsPayload }
      );
      console.log("Response from backend:", response.data);

      console.log('Selected products submitted successfully.');
      
      // Clear checked products after submission
      setCheckedProducts([]);
      console.log("Checked products cleared after submission.");
      
    } catch (error) {
      console.error('Error submitting selected products:', error);
    }
  };
  
  // Function to refresh the page when the user clicks "Continue" in the modal
  const handleContinue = () => {
    window.location.reload(true);
  };  
  
const handleExportPDF = () => {
  const doc = new jsPDF();
  doc.text('Scanned Items', 20, 10);
  const tableColumn = ["Product Name", "Counted Inventory", "Difference"];
  const tableRows = [];

  session.items.forEach(item => {
    const itemData = [
      item.title,
      item.countedInventory,
      item.countedInventory - item.currentInventory,
    ];
    tableRows.push(itemData);
  });

  doc.autoTable(tableColumn, tableRows, { startY: 20 });
  doc.save('inventory_count.pdf');
};

const handleExportExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(
    session.items.map(item => ({
      "Product Name": item.title,
      "Counted Inventory": item.countedInventory,
      "Difference": item.countedInventory - item.currentInventory,
    }))
  );
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventory Count');
  XLSX.writeFile(workbook, 'inventory_count.xlsx');
};

const handleAddBarcode = async () => {
  setSelectedProduct(null);
  if (!selectedProduct || !scannedBarcode) {
    setError('Please select a product and provide a barcode.');
    setOpenSnackbar(true);
    return;
  }

  if (tabValue !== 0 && (!quantity || quantity <= 0)) {
    setError('Please enter a valid quantity for the case.');
    setOpenSnackbar(true);
    return;
  }

  setLoading(true);

  try {
    
  if (tabValue === 0) {
    const response = await axios.put(
      `https://shopifybackend.mustafaemresahin.com/api/inventory-counts/products/${selectedProduct.id}/update-barcode`, // Use Shopify's variantId
      {
        barcode: scannedBarcode,
      }
    );
    console.log(response);

    setSuccessMessage('Barcode added for individual product successfully.');
  }
    else {
      console.log('hello', scannedBarcode, selectedProduct._id, quantity);
      await axios.post('https://shopifybackend.mustafaemresahin.com/api/cases', {
        caseBarcode: scannedBarcode,
        productId: selectedProduct._id, // Use _id instead of id
        quantity,
      });
      setSuccessMessage('Barcode added for case successfully.');
    }

    setOpenSnackbar(true);
  } catch (err) {
    setError('Error updating the product with the new barcode.');
    setOpenSnackbar(true);
  } finally {
    setLoading(false);
    setOpenAddBarcodeModal(false);
    setError('');
    const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/products/barcodes');
    setProducts(response.data);
    setScannedBarcode('');
  }
};

const handleTabChange = (event, newValue) => {
  setTabValue(newValue); // Update the tab value
};

  if (loading) {
    return <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />;
  }

  return (
    <Container>
      
      <Modal open={openAddBarcodeModal} setOpenAddBarcodeModal={false} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper
        sx={{
          p: 4,
          width: { xs: '90%', sm: '500px' },
          borderRadius: '16px',
          boxShadow: 5,
          position: 'relative',
        }}
      >
        {/* Close Button */}
        <IconButton
          sx={{ position: 'absolute', top: 16, right: 16 }}
          onClick={() => setOpenAddBarcodeModal(false)}
        >
          <CloseIcon />
        </IconButton>

        {/* Header */}
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center' }}>
          Add {tabValue === 0 ? 'Product' : 'Case'} Barcode
        </Typography>

        {/* Tabs for switching between Individual Product and Case */}
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            mb: 3,
            '.MuiTabs-indicator': {
              backgroundColor: '#1976d2',
            },
          }}
        >
          <Tab label="Individual Product" sx={{ textTransform: 'none' }} />
          <Tab label="Case" sx={{ textTransform: 'none' }} />
        </Tabs>

        {/* Content based on selected tab */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Scanned Barcode"
              variant="outlined"
              fullWidth
              value={scannedBarcode || ''}
              onChange={(e) => setScannedBarcode(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            {tabValue === 0 ? (
              <Autocomplete
              sx={{
                zoom: 1.25,
              }}
              options={allProducts}
              getOptionLabel={(option) =>
                option.title ? option.title.replace(' - Default Title', '') : 'Unnamed Product'
              }
              value={selectedProduct || null}
              onChange={(event, newValue) => setSelectedProduct(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product"
                  variant="outlined"
                  fullWidth
                />
              )}
              PopperComponent={(props) => (
                <Popper {...props} placement="bottom-start" style={{ width: '100%' }} />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    marginTop: '150px', // Adds spacing between the input and dropdown
                    width: '500px',
                    marginLeft: '100px',
                    maxHeight: '200px', // Sets a maximum height to the dropdown
                    overflowY: 'auto', // Enables scrolling if options exceed the max height
                    borderRadius: '4px', // Adds a subtle border radius
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds a shadow for the dropdown look
                  },
                },
              }}
            />
            ) : (
              <>
                <Autocomplete
              sx={{
                zoom: 1.25,
              }}
              options={allProducts1}
              getOptionLabel={(option) =>
                option.title ? option.title.replace(' - Default Title', '') : 'Unnamed Product'
              }
              value={selectedProduct || null}
              onChange={(event, newValue) => setSelectedProduct(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product"
                  variant="outlined"
                  fullWidth
                />
              )}
              PopperComponent={(props) => (
                <Popper {...props} placement="bottom-start" style={{ width: '100%' }} />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    marginTop: '150px', // Adds spacing between the input and dropdown
                    width: '500px',
                    marginLeft: '100px',
                    maxHeight: '200px', // Sets a maximum height to the dropdown
                    overflowY: 'auto', // Enables scrolling if options exceed the max height
                    borderRadius: '4px', // Adds a subtle border radius
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds a shadow for the dropdown look
                  },
                },
              }}
            />

                <TextField
                  label="Quantity in Case"
                  type="number"
                  fullWidth
                  value={null}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  sx={{ mt: 2 }}
                />
              </>
            )}
          </Grid>
        </Grid>

        {/* Save Button */}
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddBarcode}
            disabled={loading}
            sx={{
              textTransform: 'none',
              borderRadius: '8px',
              py: 1,
              px: 4,
              fontSize: '1rem',
              fontWeight: 'bold',
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Grid>
      </Paper>
    </Modal>

      {audioAlertVisible && (
        <Alert 
          severity="info" 
          sx={{ mb: 2 }}
          onClose={() => setAudioAlertVisible(false)}
        >
          Audio feedback is enabled for success and error actions. You can adjust the settings using the gear icon.
        </Alert>
      )}

      {audioDisabledAlertVisible && (
        <Alert 
          severity="warning" 
          sx={{ mb: 2, backgroundColor: '#FFA726', color: 'black' }} // Orange alert to indicate disabled audio
          onClose={() => setAudioDisabledAlertVisible(false)}
        >
          Audio feedback is currently disabled. Enable it in settings to hear sound feedback.
        </Alert>
      )}

      <Button variant="contained" color="info" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Go Back
      </Button>
      <Typography variant="h4" gutterBottom sx={{marginTop: '20px'}}>
        {session ? session.sessionName : 'Unnamed Session'}
        {/* Edit session name button */}
        <IconButton onClick={handleOpenModal} color="primary" sx={{ ml: 2 }}>
            <EditIcon />
          </IconButton>
        {/* Settings Icon to open audio settings modal */}
        <IconButton onClick={handleOpenSettingsModal} color="primary" sx={{ ml: 1 }}>
            <SettingsIcon />
          </IconButton>
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}
      <br></br>

{!finishStatus && (
  <>
    <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mb: 3 }}>

      {/* Scan Barcode Input */}
      <TextField
        label="Scan Barcode"
        variant="outlined"
        value={scannedBarcode}
        onChange={e => setScannedBarcode(e.target.value)}
        onKeyPress={e => e.key === 'Enter' && handleScan()}
        sx={{
          mx: 2,
          flexGrow: 1,
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'lightgray',
            },
            '&:hover fieldset': {
              borderColor: 'blue',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'blue',
            },
          },
        }}
      />

      {/* Decrement Icon */}
      <IconButton
        onClick={() => setScanMode('decrement')}
        sx={{
          backgroundColor: scanMode === 'decrement' ? 'red' : 'lightgray',
          color: 'white',
          '&:hover': {
            backgroundColor: 'darkred',
          },
          borderRadius: '8px',
          p: 1.5,
        }}
      >
        <RemoveIcon />
      </IconButton>

      {/* Increment Value Input */}
      <TextField
        type="number"
        value={incrementValue}
        onChange={(e) => setIncrementValue(Number(e.target.value))}
        inputProps={{ min: 1 }}
        sx={{
          width: '100px',
          marginRight: '10px',
          ml: 2,
          textAlign: 'center',
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'lightgray',
            },
            '&:hover fieldset': {
              borderColor: 'blue',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'blue',
            },
          },
        }}
      />

      {/* Increment Icon */}
      <IconButton
        onClick={() => setScanMode('increment')}
        sx={{
          backgroundColor: scanMode === 'increment' ? 'blue' : 'lightgray',
          color: 'white',
          '&:hover': {
            backgroundColor: 'darkblue',
          },
          borderRadius: '8px',
          p: 1.5,
        }}
      >
        <AddIcon />
      </IconButton>

    </Grid>

  </>
)}



      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
            {finishStatus && (
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Include</TableCell>
            )}
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Product Name</TableCell>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Counted Inventory</TableCell>
              <TableCell sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {session?.items?.map((item, index) => {
    const currentProduct = allProducts.find(p => p.barcode === item.barcode);
    const currentInventory = currentProduct ? currentProduct.currentInventory : 0;
    const inventoryDifference = item.countedInventory - currentInventory;

    return (
      <TableRow
        key={index}
        sx={{
          backgroundColor: inventoryDifference === 0 ? '#d4edda' : 'inherit', // Light green when difference is 0
        }}
      >
        {finishStatus && (
          <TableCell>
            <Checkbox
              checked={checkedProducts.includes(item.barcode)}
              onChange={() => handleSelectProduct(item.barcode)}
              disabled={inventoryDifference === 0}
            />
          </TableCell>
        )}
        <TableCell>{item.title}</TableCell>
        <TableCell>{item.countedInventory}</TableCell>
        <TableCell>{inventoryDifference}</TableCell> {/* Display real-time difference */}
      </TableRow>
    );
  })}
</TableBody>

        </Table>
      </TableContainer>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={error || successMessage}
      />

      {/* Modal for editing session name */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="edit-session-name-modal"
        aria-describedby="modal-to-edit-session-name"
      >
        <Paper sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, width: '400px', margin: 'auto', mt: '20vh', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
          <Typography id="edit-session-name-modal" variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Edit Session Name
          </Typography>
          <TextField
            label="Session Name"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={newSessionName}
            onChange={(e) => setNewSessionName(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={saveSessionName} fullWidth sx={{ borderRadius: '8px' }}>
            Save
          </Button>
        </Paper>
      </Modal>

      {/* Modal for audio settings */}
      <Modal
        open={openSettingsModal}
        onClose={handleCloseSettingsModal}
        aria-labelledby="audio-settings-modal"
        aria-describedby="modal-to-audio-settings"
      >
        <Paper sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, width: '400px', margin: 'auto', mt: '20vh', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
          <Typography id="audio-settings-modal" variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Audio Settings
            <IconButton onClick={handleCloseSettingsModal} sx={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
          </Typography>
          <FormControlLabel
            control={<Switch checked={audioEnabled} onChange={toggleAudioEnabled} />}
            label="Enable Audio Feedback"
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={playSuccessSound} sx={{ mr: 2, mb: 1 }}>
            Test Success Sound
          </Button>
          <Button variant="contained" color="error" onClick={playErrorSound}>
            Test Error Sound
          </Button>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Enable or disable sound feedback for success and error actions. Test the sounds to ensure they are set to your preference.
          </Typography>
        </Paper>
      </Modal>

      <Modal
  open={openValidationModal}
  onClose={() => setOpenValidationModal(false)}
  aria-labelledby="validation-modal"
  aria-describedby="modal-to-show-submitted-products"
>
  <Paper sx={{ p: 4, width: '80%', margin: 'auto', mt: '10vh', maxHeight: '80vh', overflowY: 'auto' }}>
    <Typography variant="h6" component="h2" gutterBottom>
      {isLoading ? 'Processing...' : 'Submitted Products Summary'}
    </Typography>

    {isLoading ? (
      <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
    ) : (
      <>
        <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Previous Inventory</TableCell>
                <TableCell>New Inventory</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submittedProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.title}</TableCell>
                  <TableCell>{product.previousInventory}</TableCell>
                  <TableCell>{product.newInventory}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={!isContinueEnabled} // Disable until delay is over
          sx={{ mt: 3 }}
        >
          Continue
        </Button>
      </>
    )}
  </Paper>
</Modal>

      {finishStatus && (
        <div>
          <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleExportPDF}>
            Export to PDF
          </Button>
          <Button variant="contained" color="primary" sx={{ mb: 2, ml: 2 }} onClick={handleExportExcel}>
            Export to Excel
          </Button>
        </div>
      )}

      {!finishStatus ? (
        <Button variant="contained" color="success" onClick={handleFinishSession} sx={{ mb: 2 }}>
          Finish Session
        </Button>
      ) : (
        <Button variant="contained" color="warning" onClick={handleResumeSession} sx={{ mb: 2 }}>
          Resume Session
        </Button>
      )}
      {finishStatus && (
        <Button variant="contained" color="primary" onClick={handleSubmitSelectedProducts} sx={{ mb: 2 }}>
          Submit Selected Products
        </Button>
      )}

    </Container>
  );
}

export default InventoryCount;
