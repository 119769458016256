import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';
import { Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';

// Register necessary components with ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function SalesPageGraphs({ productId, productTitle, quantitySoldToday }) {
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      axios
        .get(`https://shopifybackend.mustafaemresahin.com/api/inventory/${productId}`)
        .then((response) => {
          setInventoryData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching inventory data:', error);
          setError(true);
          setLoading(false);
        });
    }
  }, [productId]);

  const handleClick = () => {
    navigate(`/todays-graphs/product/${productId}`);
  };

  if (loading) {
    return (
      <Card sx={{ width: '100%', padding: '20px', textAlign: 'center' }}>
        <CircularProgress />
      </Card>
    );
  }

  if (error) {
    return (
      <Card
        sx={{
          width: '100%',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#ffe6e6',
          color: '#d32f2f',
          border: '1px solid #d32f2f',
        }}
      >
        <Typography variant="body1">Failed to load data</Typography>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        cursor: 'pointer',
        width: '100%',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
        },
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            {productTitle}
          </Typography>
          <Box
            sx={{
              backgroundColor: '#ff4d4f',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '1.25rem',
              padding: '4px 12px',
              borderRadius: '8px',
              minWidth: '60px',
              textAlign: 'center',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
            }}
          >
            {quantitySoldToday} Sold
          </Box>
        </Box>
        <Line
          data={{
            labels: inventoryData.map((_, index) => `Point ${index + 1}`),
            datasets: [
              {
                label: 'Inventory Levels',
                data: inventoryData.map((entry) => entry.quantity),
                fill: false,
                borderColor: '#1976d2',
                tension: 0.3,
                pointHitRadius: 5,
                pointHoverRadius: 5,
                pointBackgroundColor: '#1976d2',
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: { display: false },
              tooltip: { enabled: true },
            },
            interaction: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              x: {
                grid: { display: false },
                ticks: { display: false },
              },
              y: {
                beginAtZero: true,
                grid: { color: '#e0e0e0' },
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default SalesPageGraphs;
