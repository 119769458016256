import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [todaysSales, setTodaysSales] = useState([]);
  const [orders, setOrders] = useState([]); // State for orders
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reset error and loading state
        setError(null);
        setLoading(true);

        // Fetch data from the API
        const response = await axios.get('https://shopifybackend.mustafaemresahin.com/api/todays-sales');
        const { salesData, ordersToday } = response.data;

        // Update state with fetched data
        setTodaysSales(salesData);
        setOrders(ordersToday);
        console.log('sales', salesData);
        console.log('orders', ordersToday);
      } catch (error) {
        console.error("Error fetching today's sales and orders:", error);

        // Set a user-friendly error message
        setError('Failed to fetch data. Please check your connection or try again later.');
      } finally {
        // Ensure loading state is reset
        setLoading(false);
      }
    };

    fetchData(); // Call the async function
  }, []); // Empty dependency array ensures it runs only once on mount

  const handleViewGraphs = () => {
    navigate('/todays-graphs');
  };

  const titleClick = (productId) => {
    navigate(`/todays-graphs/product/${productId}`);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2e3b55' }}>
        Dashboard
      </Typography>
      <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2e3b55' }}>
          Today's Sales
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100px' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        ) : (
          <>
            {/* Sales Data Table */}
            {todaysSales.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Product Title
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Quantity Sold
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {todaysSales.map((product) => (
                      <TableRow hover key={product.shopifyId}>
                        <TableCell
                          onClick={() => titleClick(product.shopifyId)}
                          sx={{ cursor: 'pointer', color: '#1976d2', fontWeight: 'bold' }}
                        >
                          {product.title}
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333' }}>
                          {product.quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" sx={{ mt: 2, color: '#666' }}>
                No sales data available for today.
              </Typography>
            )}

            {/* Orders Details Table */}
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2e3b55', mt: 4 }}>
              Orders Details
            </Typography>
            {orders.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 2, overflowX: 'auto' }}>
                <Table sx={{ minWidth: 750, tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Order ID
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Customer
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', color: '#2e3b55' }}
                      >
                        Products
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow key={order.id}>
                        <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>{order.id}</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                          {order.customer
                            ? `${order.customer.first_name || 'Unknown'} ${order.customer.last_name || ''}`.trim()
                            : 'Unknown Customer'}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                          {order.customer?.email || 'No Email'}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                          {new Date(order.created_at).toLocaleString('en-US', {
                            dateStyle: 'medium',
                            timeStyle: 'short',
                          })}
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333' }}>
                          $ {order.total_price}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: '#333' }}>
                          {order.line_items?.map((item) => (
                            <div key={item.product_id}>
                              {item.title} (x{item.quantity})
                            </div>
                          )) || 'No Products'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" sx={{ mt: 2, color: '#666' }}>
                No orders available for today.
              </Typography>
            )}
          </>
        )}
        {!loading && (
          <Button
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: '#1976d2',
              color: '#fff',
              ':hover': {
                backgroundColor: '#115293',
              },
            }}
            onClick={handleViewGraphs}
          >
            View Graphs of Today's Sales
          </Button>
        )}
      </Paper>
    </Box>
  );
}

export default Dashboard;
